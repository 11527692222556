<template>
  <div class="home">
    <menuTemplate :screens="screens" :menu="menu" :playerInstance="playerInstance"></menuTemplate>
    <router-view :playerInstance="playerInstance"></router-view>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { eventBus } from "@/eventBus";

export default {
  props: {
    menuDetail: {
      type: Object,
    },
    playerInstance: {
      type: Object,
    },
  },
  data() {
    return {
      menu: null,
      screens: null,
    };
  },
  computed: {
    ...mapGetters(["appConfig"]),
  },
  watch: {
    $route() {
      this.actMetaData();
    },
    menuDetail(val) {
      if (val) {
        this.loadTemplate(val);
      }
    },
  },
  created() {
    // console.log("menu", this.menu);
    if (this.appConfig.featureEnabled && this.appConfig.featureEnabled.isDeckingEnabled) {
      this.loadInitTemplateDecking();
    } else {
      this.loadInitialTemplate();
    }
  },
  mounted() {
    document.getElementById("body").style.overflowY = "auto";
    this.actMetaData();
  if (this.$route.fullPath.includes("/login")){
      setTimeout(() => {
        eventBus.$emit("achieveLogin", true);    
      }, 500);
    }
    // this.loadRoutedTemplate();
    // eventBus.$on("seeAll-from-home", this.loadSeeAllTemplate);
  },
  methods: {
    // function to load the template on initial app load.
    actMetaData() {
      if (window.location.pathname === '/') {
        var allMetaElements = document.getElementsByTagName('meta');
        document.title = "Ultra Gaane: Video Songs & More";
        // for (var metaValue = 0; metaValue < allMetaElements.length; metaValue++) {
        //   if (allMetaElements[metaValue].getAttribute("name") == "description") {
        //     allMetaElements[metaValue].setAttribute('content', "आपली आवड जपणारा.. Watch HD quality 2000+ hours of exclusive content on Ultra Gaana Marathi OTT Platform. Explore movies, web shows & music with our affordable subscription plans.");
        //   }

        //   if (allMetaElements[metaValue].getAttribute("property") == "og:description") {
        //     allMetaElements[metaValue].setAttribute('content', "आपली आवड जपणारा.. Watch HD quality 2000+ hours of exclusive content on Ultra Gaana Marathi OTT Platform. Explore movies, web shows & music with our affordable subscription plans.");
        //   }
        //   if (allMetaElements[metaValue].getAttribute("name") == "twitter:description") {
        //     allMetaElements[metaValue].setAttribute('content', "आपली आवड जपणारा.. Watch HD quality 2000+ hours of exclusive content on Ultra Gaana Marathi OTT Platform. Explore movies, web shows & music with our affordable subscription plans.");
        //   }
        //   if (allMetaElements[metaValue].getAttribute("name") == "keyword") {
        //     allMetaElements[metaValue].setAttribute('content', "Ultra Gaana, Marathi OTT platform, old & new movies, hollywood dubbed movies, music videos, kids shows, web series,");
        //   }
        //   if (allMetaElements[metaValue].getAttribute("property") == "og:title") {
        //     allMetaElements[metaValue].setAttribute('content', "Ultra Gaana: Marathi OTT Platform - Watch Movies, Kids Shows & More");
        //   }
        //   if (allMetaElements[metaValue].getAttribute("name") == "twitter:title") {
        //     allMetaElements[metaValue].setAttribute('content', "Ultra Gaana: Marathi OTT Platform - Watch Movies, Kids Shows & More");
        //   }
        // }
      }
    },
    loadRoutedTemplate() {
      if (this.$route.params.link) {
        this.loadTemplate(this.$route.params.link);
        this.menu = this.$route.params.link;
      }
    },

    loadInitTemplateDecking () {
      let deckingconfig = localStorage.getItem("appDeckingConfig") ? JSON.parse(localStorage.getItem("appDeckingConfig")) : '';
      if (!this.appConfig) {
        this.loadTemplate(null);
      } else {
        // this.loadTemplate(this.appConfig.menu[0]);
        let profileDetails = localStorage.getItem("subscriberProfileDetails");
        if (profileDetails) {
          profileDetails = JSON.parse(profileDetails);
        }
        if (profileDetails && profileDetails.kidsmode === "YES") {
          let itemItem = "";
          deckingconfig.screens.forEach((menu) => {
            if (menu.profileTypes && (menu.profileTypes.includes("KIDS") || menu.profileTypes.includes("ALL")) && menu.id.includes("Home")) {
               itemItem = menu
            }
          });

          this.screens = itemItem.sections;
          this.screens.sections = [];
          this.screens.sections = itemItem.sections;
          this.menu = itemItem;
        } else {
          let menuTemp = null;

          deckingconfig.screens.forEach((menu) => {
            //  if (menuTemp) return;
            if (menu.id.includes("Home") && menu.screenPosition == "TOP") {
              menuTemp = menu;
            }
          });
          
          // this.loadTemplate(menuTemp);
            this.screens = menuTemp.sections;
            this.screens.sections = [];
            this.screens.sections = menuTemp.sections;

            this.menu = menuTemp;

        }
      }

    },

    //load initial Template i.e home
    loadInitialTemplate() {
      if (!this.appConfig) {
        this.loadTemplate(null);
      } else {
        // this.loadTemplate(this.appConfig.menu[0]);
        let profileDetails = localStorage.getItem("subscriberProfileDetails");
        if (profileDetails) {
          profileDetails = JSON.parse(profileDetails);
        }
        if (profileDetails && profileDetails.kidsmode === "YES") {
          this.appConfig.menu.forEach((menu) => {
            if (menu.profileTypes && menu.profileTypes.includes("KIDS")) {
              this.loadTemplate(menu);
            }
          });
        } else {
          let menuTemp = null;

          this.appConfig.menu.forEach((menu) => {
            if (menu.id === "HOME") {
              menuTemp = menu;
            }
          });
          
          this.loadTemplate(menuTemp);

        }
      }
    },

    //function to load the home template screen with provided menu screens.
    loadTemplate(menu) {
      if (menu) {
        this.menu = menu;

        let index = this.appConfig.screens.findIndex((element) => {
          return this.menu.id === element.id;
        });

        if (index > -1) {
          this.screens = this.appConfig.screens[index];
        }
      }
    },
  },
  components: {
    menuTemplate: () => import(/* webpackChunkName: "menuTemplate" */ "@/components/Templates/menuTemplate.vue"),
  },
};
</script>

<style lang="scss"></style>
